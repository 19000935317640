import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

import { ModalContainer } from 'shared/components/ModalContainer/ModalContainer';

import styles from './PermissionModal.module.scss';

interface PermissionModalProps {
	title: string;
	isOpen: boolean;
	handleModalClose: VoidFunction;
	content: React.ReactNode;
	showCloseButton?: boolean;
}

const PermissionModal: FC<PermissionModalProps> = ({
	title,
	isOpen,
	handleModalClose,
	content,
	showCloseButton,
}: PermissionModalProps) => {
	return (
		<ModalContainer isOpen={isOpen} title={title} onClose={handleModalClose}>
			<Box className={styles.contentWrapper}>
				{showCloseButton && (
					<IconButton className={styles.closeButton} onClick={handleModalClose}>
						<CloseIcon />
					</IconButton>
				)}
				{content}
			</Box>
		</ModalContainer>
	);
};

export default PermissionModal;
