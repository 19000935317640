import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, LinearProgress, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import PermissionModal from 'shared/components/PermissionModal/PermissionModal';
import { PrivateLayout } from 'shared/components/PrivateLayout';
import { Sidebar } from 'shared/components/Sidebar';
import { RoutesEnum } from 'shared/enums';
import { getDaysLeftV2 } from 'shared/helpers/billingHelpers';
import { useSearchHook } from 'shared/hooks/useAdsManagement';
import { useGetUser } from 'shared/hooks/useGetUser';
import { usePermissionModal } from 'shared/hooks/usePermissionModal';

import BillingAndPlansTabV2 from 'app/pages/settings/components/BillingAndPlansTab/BillingAndPlansTabV2';
import { useGetUsersTeams } from 'app/pages/settings/hooks/useGetUsersTeams';
import { useSidebar } from 'core/SidebarProvider/useSidebar';
import { useUser } from 'core/UserProvider/useUser';
import { ModalContainer } from '../ModalContainer/ModalContainer';

const initializePendo = (visitorId: number) => {
    if (window.pendo && visitorId) {
        window.pendo.initialize({
            visitor: { id: visitorId },
            account: { id: visitorId },
        });
    }
};

const PrivateRoute: FC = () => {
	const {
		user,
		actions: { setLoggedInUser },
	} = useUser();
	const { data: extendedUser } = useGetUser(user?.id);
	const { data: teams } = useGetUsersTeams();
	const { isOpen, content, handleModalClose, handleModalOpen, setContent } = usePermissionModal();
	const navigate = useNavigate();
	const location = useLocation();
	const { isSidebarOpen } = useSidebar();
	const [isFirstLoginModalOpen, setIsFirstLoginModalOpen] = useState(false);
	const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
	const { handleResetSearch } = useSearchHook();

	const {
		activeSubscription,
		subscriptionEndsAt,
		isLoading: isSubscriptionLoading,
	} = useActiveSubscriptionInfo(user?.id);
	const isUserInvited = teams?.owner.length === 0;

	const handleFirstTimeLogin = useCallback(() => {
		if (user?.metadata?.team_first_login && location.pathname !== RoutesEnum.SETTINGS) {
			setIsFirstLoginModalOpen(true);
		}
	}, [user, location.pathname]);

	useEffect(() => {
		if (isSubscriptionLoading) return;
		setIsSubscriptionChecked(true);

		if (
			!extendedUser ||
			[
				RoutesEnum.PAYMENTSTATUS as string,
				RoutesEnum.CHECKOUT as string,
				RoutesEnum.SUBSCRIPTION_CONFIRMATION as string,
			].includes(location.pathname)
		) {
			handleModalClose();
			return;
		}

		const daysLeft = subscriptionEndsAt ? getDaysLeftV2(subscriptionEndsAt) : 'Expired';

		if (daysLeft === 'Expired') {
			isUserInvited
				? setContent(
						<Box sx={{ maxWidth: '600px', textAlign: 'center' }}>
							<Typography variant="body1" color={'#495057'}>
								It looks like your subscription plan has come to an end. Please
								reach out to your team owner to renew your plan or explore available
								options. If you have any questions or need assistance, your team
								owner is here to help!
							</Typography>
						</Box>
					)
				: setContent(<BillingAndPlansTabV2 />);
			handleModalOpen();
		} else {
			handleModalClose();
		}
	}, [
		activeSubscription,
		subscriptionEndsAt,
		extendedUser,
		handleModalClose,
		handleModalOpen,
		isUserInvited,
		isSubscriptionLoading,
		location.pathname,
		setContent,
	]);

	useEffect(() => {
		const handleRefreshToken = (event: Event) => {
			const customEvent = event as CustomEvent<{ token: string; refreshToken: string }>;

			if (extendedUser) {
				setLoggedInUser({
					...extendedUser,
					access_token: customEvent.detail.token,
					refresh_token: customEvent.detail.refreshToken,
				});
			}
		};

		window.addEventListener('refreshToken', handleRefreshToken);

		return () => {
			window.removeEventListener('refreshToken', handleRefreshToken);
		};
	}, [extendedUser, setLoggedInUser]);

	useEffect(() => {
		if (!user && location.pathname !== RoutesEnum.LOGIN) {
			navigate(RoutesEnum.LOGIN);
		}
	}, [location.pathname, navigate, user]);

	useEffect(() => {
		if (user) {
			handleFirstTimeLogin();
			handleResetSearch();
		}
	}, [handleFirstTimeLogin, handleResetSearch, location.pathname, user]);

    useEffect(() => {
        if (user && user.id) {
            initializePendo(user.id);
        }
    }, [user]);

	if (!user || isSubscriptionLoading || !isSubscriptionChecked) return null;

	return (
		<PrivateLayout>
			<PrivateLayout.Sidebar isSidebarOpen={isSidebarOpen}>
				<Sidebar />
			</PrivateLayout.Sidebar>
			<PrivateLayout.Content isSidebarOpen={isSidebarOpen}>
				<Suspense fallback={<LinearProgress />}>
					<Outlet />
				</Suspense>
			</PrivateLayout.Content>
			<PermissionModal
				title="Your Plan Has Expired"
				content={content}
				isOpen={isOpen}
				handleModalClose={handleModalClose}
			/>
			<ModalContainer isOpen={isFirstLoginModalOpen} title="First Time Login">
				<Box display="flex" flexDirection="column" alignItems="center" gap="16px" p="24px">
					<Typography variant="body1">
						Please set your name and change your password before accessing the app!
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setIsFirstLoginModalOpen(false);
							navigate(RoutesEnum.SETTINGS);
						}}
						sx={{ mt: 2, width: '200px' }}
					>
						Go to Settings
					</Button>
				</Box>
			</ModalContainer>
		</PrivateLayout>
	);
};

export default PrivateRoute;
