import { useState } from 'react';

import { Box, Button } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import { useGetDashboard } from 'shared/api/hooks/billing/useGetDashboard';
import { usePlansAndAddOns } from 'shared/api/hooks/billing/usePlansAndAddons';
import { sortByPriceForInterval } from 'shared/helpers/billingHelpers';
import { useGetUser } from 'shared/hooks/useGetUser';

import PlanItemV2 from './components/PlanItemV2/PlanItemV2';

import styles from './BillingAndPlansTab.module.scss';

const useBillingData = () => {
	const { data: user } = useGetUser();
	const { data: dashboardData } = useGetDashboard(user?.id);

	return { user, dashboardData };
};

const BillingAndPlansTabV2 = () => {
	const { user, dashboardData } = useBillingData();
	const [billingInterval] = useState<'month' | 'year'>('month');
	const { plans } = usePlansAndAddOns(billingInterval);
	const { isSubscriptionActive, activeSubscription } = useActiveSubscriptionInfo(user?.id);

	if (!plans || !user) return null;

	const handleManagePlans = () => {
		if (dashboardData?.session_url) {
			window.location.href = dashboardData?.session_url;
		}
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.plansWrapper}>
				{sortByPriceForInterval(plans, billingInterval).map((plan) => (
					<PlanItemV2
						key={plan.id}
						item={plan}
						interval={billingInterval}
						selectedPlanId={activeSubscription?.plan.product}
						billingInterval={billingInterval}
					/>
				))}
			</Box>
			{isSubscriptionActive && (
				<Button
					variant="outlined"
					onClick={handleManagePlans}
					color="primary"
					disabled={!dashboardData?.session_url}
					sx={{ width: '300px' }}
				>
					Manage plans
				</Button>
			)}
		</Box>
	);
};

export default BillingAndPlansTabV2;
