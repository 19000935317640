import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

if (import.meta.env.VITE_APP_ENV !== "production") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
        failedRequestTargets: [/.*/],    
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^http:\/\/localhost(:\d+)?/,
      /^https:\/\/test\.viralswiper\.xyz/,
      /^https:\/\/staging\.viralswiper\.xyz/,
      /^https:\/\/app\.aduniverse\.ai/,
    ],
    sendDefaultPii: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV || "development",
    release: `ad-universe@${import.meta.env.VITE_APP_VERSION}`,
  });

  window.addEventListener("error", (event) => {
    Sentry.captureException(event.error);
  });

  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event.reason);
  });
}
